import EntryProxy from '@/app/proxies/EntryProxy';
import MailDomainProxy from '@/app/proxies/MailDomainProxy';
import AddressProxy from '@/app/proxies/AddressProxy';

export default {
    namespaced: true,
    state: {
        entry: {
            score: 4,
            prize: 4500
        }
    },

    getters: {
        all: state => state.entry
    },

    mutations: {
        assign: ( state, payload ) =>
        {
            state.entry = Object.assign({}, state.entry, payload );
        },

        resetId: state =>
        {
            state.entry.id = null;
        }
    },

    actions: {
        merge: ( context, payload ) =>
        {
            context.commit( 'assign', payload );
        },

        save: ( context, payload ) =>
        {
            context.commit( 'assign', payload );

            context.commit( 'resetId' );

            return new EntryProxy()
                .create( context.getters.all )
                .then( response =>
                {
                    context.commit( 'assign', response );
                    return response;
                })
                .catch( error =>
                {
                    return Promise.reject( error );
                });
        },

        checkMailDomain: ( context, payload ) =>
        {
            // payload = { emailaddress: <emailaddress> }

            return new MailDomainProxy()
                .create( payload )
                .then( response =>
                {
                    return response;
                })
                .catch( error =>
                {
                    return error;
                    // return Promise.reject( error );
                });
        },

        checkAddress: ( context, payload ) =>
        {
            return new AddressProxy()
                .create( payload )
                .then( response =>
                {
                    return response;
                })
                .catch( error =>
                {
                    return error;
                });
        }
    }
};
