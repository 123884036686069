import Vue from 'vue';
import Router from 'vue-router';

import store from '@/app/store';
import { gsap } from '@/vendor';

Vue.use( Router );

let appRouter = new Router({
    mode: 'history',
    routes: [
        // generated-routes
        {
            path: '/',
            name: 'Landing',
            component: () =>
            {
                return import( /* webpackChunkName: "landing" */ '@/app/pages/Landing' );
            },
            meta: {
                pageType: 'promotion-landing'
            }
        },

        {
            path: '/spel',
            name: 'Game',
            component: () =>
            {
                return import( /* webpackChunkName: "game" */ '@/app/pages/Game' );
            },
            meta: {
                pageType: 'promotion-content'
            }
        },

        {
            path: '/gegevens',
            name: 'Naw',
            component: () =>
            {
                return import( /* webpackChunkName: "naw" */ '@/app/pages/Naw' );
            },

            beforeEnter: ( to, from, next ) =>
            {
                if( window.location.hostname.indexOf( '192.168' ) > -1 )
                {
                    next();
                    return;
                }

                if( store.getters['entry/all'].score === -1 )
                {
                    next( '/' );
                }
                else
                {
                    next();
                }
            },

            children: [
                {
                    path: '1',
                    name: 'Naw1',
                    component: () =>
                    {
                        return import( /* webpackChunkName: 'Naw1' */ '@/app/pages/naw/Naw1' );
                    },
                    meta: {
                        pageType: 'promotion-leadform',
                        step: 1
                    }
                },
                {
                    path: '2',
                    name: 'Naw2',
                    component: () =>
                    {
                        return import( /* webpackChunkName: 'Naw2' */ '@/app/pages/naw/Naw2' );
                    },
                    meta: {
                        pageType: 'promotion-leadform',
                        step: 2
                    }
               },
                {
                    path: '3',
                    name: 'Naw3',
                    component: () =>
                    {
                        return import( /* webpackChunkName: 'Naw3' */ '@/app/pages/naw/Naw3' );
                    },
                    meta: {
                        pageType: 'promotion-leadform',
                        step: 3
                    }
                }
            ]
        },

        {
            path: '/bedankt',
            name: 'Bedankt',
            component: () =>
            {
                return import( /* webpackChunkName: "bedankt" */ '@/app/pages/Bedankt' );
            },
            meta: {
                pageType: 'promotion-confirmation'
            },

            beforeEnter: ( to, from, next ) =>
            {
                if( window.location.hostname.indexOf( '192.168' ) > -1 )
                {
                    next();
                    return;
                }

                if( !store.getters['entry/all'].id )
                {
                    next( '/' );
                }
                else
                {
                    next();
                }
            }
        },

        {
            path: '/test',
            name: 'Test',
            component: () =>
            {
                return import( /* webpackChunkName: "test" */ '@/app/pages/Test' );
            },
            meta: {
                pageType: 'promotion-confirmation'
            }
        }
    ]
});

appRouter.beforeEach( ( to, from, next ) =>
{
    /*
    if( to.fullPath === '/bedankt' )
    {
        let entry = store.getters['entry/all'];

        dataLayer.push({
            event: 'confirmation',
            newsletter_optin: entry.kit,
            transaction_id: 'stl-europarks-' + entry.id
        });
    }

    let trackingAlias = {
        '/': '/landing',
        '/loten': '/loten',
        '/gegevens': '/gegevens',
        '/gegevens/1': '/gegevens-1',
        '/gegevens/2': '/gegevens-2',
        '/gegevens/3': '/gegevens-3',
        '/overzicht': '/overzicht',
        '/bedankt': '/bedankt'
    };

    dataLayer.push({
        event: 'pageview',
        page_path: trackingAlias[ to.fullPath ]
    });
    */

    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    gsap.to( 'html, body', { scrollTop: 0, ease: 'sine.inOut' });

    next();
});

export default appRouter;
