<template>
    <div class="main-layout simple">
        <div :class="{ 'bg-visual': true }">
        </div>

        <img class="stl-label" src="/static/img/stl-label.png" />

        <div class="content">
            <svg class="golden-border" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :viewBox="'0 0 ' + wWidth + ' ' + wHeight" preserveAspectRatio="none" >
                <defs>
                    <linearGradient id="gold-gradient" x1="0%" y1="0%" x2="0%" y2="100%" gradientUnits="userSpaceOnUse" gradientTransform="rotate(-15)">
                        <stop offset="0%" stop-color="#e3bd52"/>
                        <stop offset="100%" stop-color="#9d681b"/>
                    </linearGradient>
                    <clipPath id="kijkgat">
                        <rect x="0" y="0" :width="wWidth" :height="borderWidth" />
                        <rect x="0" y="0" :width="borderWidth" height="100%" />
                        <rect :x="( wWidth - borderWidth ) + 'px'" y="0" :width="borderWidth" height="100%" />
                        <rect x="0" :y="wHeight - borderWidth" :width="wWidth" :height="borderWidth" />
                    </clipPath>
                </defs>
                <rect x="0" y="0" width="100%" height="100%" fill="url(#gold-gradient)" clip-path="url(#kijkgat)" />
            </svg>

            <slot></slot>
        </div>

        <footer>
            <div class="content-wrapper">
                <div class="footer-top">
                    <img src="/static/img/logos/footer.png?v2" />
                    <div class="footer-18plus hide-for-small">
                        <p>Wat kost gokken jou?<strong> Stop&nbsp;op&nbsp;tijd.&nbsp;18+</strong></p>
                    </div>
                </div>
                <p class="footer-18plus hide-for-medium-up">Wat kost gokken jou?<strong> Stop&nbsp;op&nbsp;tijd.&nbsp;18+</strong></p>
                <p class="disclaimer">Voor het organiseren van Staatsloterij is aan Staatsloterij bv vergunning verleend door de Kansspelautoriteit, trekking Laan van Hoornwijck 55 te Rijswijk. <br class="hide-for-small"/>Deelname vanaf 18 jaar. Als je aan deze actie meedoet, dan accepteer je onze <a :href="'/static/pdf/actievoorwaarden.pdf'" target="_blank">actievoorwaarden</a>. | <a href="https://www.nederlandseloterij.nl/privacy" target="_blank">Privacy Statement</a> Nederlandse Loterij</p>
            </div>
        </footer>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    export default {
        data()
        {
            return {
                wHeight: document.documentElement.getBoundingClientRect().height,
                wWidth: window.innerWidth,
                borderWidth: 15
            };
        },

        computed: {
            polyPath()
            {
                return '0,' + this.wHeight + ' ' + this.wWidth + ',' + this.wHeight + ' ' + this.wWidth + ',' + ( this.wHeight - 75 ) + ' 0,' + ( this.wHeight - 50 );
            }
        },

        mounted()
        {
            window.addEventListener( 'resize', this.onResize );
            this.onResize();
        },

        methods: {
            onResize()
            {
                this.wHeight = document.documentElement.getBoundingClientRect().height;
                this.wWidth = window.innerWidth;
            }
        }
    };
</script>
